import React from 'react';
import logo from './logo.svg';
import './App.css';
import file from './assets/reoda-app-3_10_release.apk'

function App() {
  return (
    <div className="App">
      <div className="row">
        <p className="header">ReOda App:</p>
        <a href={file} download="reoda-app-3_10_release.apk" className="download" target="_blank">Download</a>
      </div>
    </div>
  );
}

export default App;
